import { Fragment, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { NoResults } from 'basic-components';
import { CommonService, HooksService } from 'dashboard-services';
import { globalActions } from 'primary-components';

import FORMULAS from 'resources/constants/Formulas.js';
import { ExcelUtils, FileUtils, GAUtils } from 'utils';

import LoadedFileRow from './LoadedFileRow';

import { When } from 'react-if';

import './LoadedFiles.scss';

const { validateTable, searchFilesCore } = FileUtils

const setFilesWithReplace = ({ setFiles, file }) => {
  setFiles(files => {
    const indexes = []
    files.forEach((sFile, sFileIndex) => {
      if(sFile.fileName === file.fileName && sFile.groupName === file.groupName) {
        indexes.push(sFileIndex)
      }
    })
    let newFiles = files;
    indexes.forEach(index => {
      if(files[index]?.address === file.address) {
        newFiles = newFiles.replace(file, index)
      }
    })
    return newFiles
  })
}

const SEARCH_PREFIX = "LOAD_FILES", REFRESH_PREFIX = "REFRESH_LOADED_FILES"
const LoadedFiles = () => {
  const dispatch = useDispatch(),
        [files, setFiles] = useState(),
        query = useSelector(state => state.datalakeState.query),
        items = useMemo(() => files?.filter(file => query ? file.fileName.toLowerCase().search(query.toLowerCase()) > -1 : true), [files, query]),
        refreshAListRef = useRef(),
        getFileDef = useCallback(({ file, noFetching, requestIdPrefix = SEARCH_PREFIX }) => 
          dispatch(searchFilesCore({ query: `name.keyword=${file.fileName}&groupName=${file.groupName}&latest=true`, size: 1, noFetching, requestIdPrefix }))
            .then(response => {
              const returned = response.items?.[0]
              if(!returned) {
                return undefined;
              }
              return Object.assign(file, returned)
            })
        , [dispatch]),
        refreshUserFilesAndDefs = useCallback(({ noFetching = false, requestIdPrefix } = {}) => {
          if(refreshAListRef.current) {
            return Promise.resolve()
          }
          refreshAListRef.current = true;
          const reqId = CommonService.generateUID()
          !noFetching && dispatch(globalActions.handleRequest({ id: reqId }))
          ExcelUtils.getTablesInSpreadSheets({ validateTable }).then(items => {
            const promises = items.filter(i => i !== undefined).map(file => getFileDef({ file, noFetching, requestIdPrefix }))
            return Promise.all(promises)
              .then(items => {
                setFiles(items.filter(d => d !== undefined))
              })
          })
          .catch(err => {
            dispatch(globalActions.getMessageStore()).error(String(err))
            console.error(String(err))
          })
          .finally(() => {
            refreshAListRef.current = false;
            !noFetching && dispatch(globalActions.handleRequest({ id: reqId }))
          })
        }, [dispatch, getFileDef]),
        refreshFileDef = useCallback(({ file }) => getFileDef({ file }).then(result => setFilesWithReplace({ setFiles, file: result })), [getFileDef]),
        refreshFile = useCallback(({ file, withRefresh = true } = {}) => {
          withRefresh && refreshFileDef({ file })
          GAUtils.sendEvent({
            category: "action",
            action: "refresh_file",
            label: "refresh"
          })
          return Excel.run(context => 
            ExcelUtils
              .refreshFormulaWithAdress({ address: file?.address, formula: `=${FORMULAS.LOAD_FILE}("${file.fileName}","${file.groupName}")`, context })
              .catch(err => {
                dispatch(globalActions.getMessageStore()).error(String(err))
                console.error(String(err))
                return Promise.resolve()
              })
          )
        }, [dispatch, refreshFileDef])

  HooksService.useInterval(() => {
    refreshUserFilesAndDefs({ noFetching: true, requestIdPrefix: REFRESH_PREFIX })
  }, 15000)

  useEffect(() => {
    refreshUserFilesAndDefs()
  }, [refreshUserFilesAndDefs])

  return (
    <Fragment>
      <div className="ng-office-app__authed__content__body__item ng-office-app__authed__content__body__item--loaded">
        <div className="ng-office-app__authed__content__body__item__headers">
          <div className="ng-office-app__authed__content__body__item__headers__header ng-office-app__authed__content__body__item__headers__header--name">
            Name:
          </div>
          <div className="ng-office-app__authed__content__body__item__headers__header ng-office-app__authed__content__body__item__headers__header--address">
            Cell Reference:
          </div>
          <div className="ng-office-app__authed__content__body__item__headers__header ng-office-app__authed__content__body__item__headers__header--time">
            Last update:
          </div>
        </div>
        <div className="ng-office-app__authed__content__body__item__body ng-office-app__authed__content__body__item__body--loaded">
          <When condition={items?.length === 0}>
            {() => (
              <div className="no-results-wrapper">
                <NoResults
                    optionalText={query ? "Try to search a diffrent keyword" : "Navigate to Add New tab to add data."}
                    title="No data has been found."
                />
              </div>
            )}
          </When>
          <When condition={items?.length > 0}>
            {() => (
              items?.map(item => (
                <LoadedFileRow
                    {...item}
                    key={item.fid + item.address}
                    refreshFile={refreshFile}
                />
              ))
            )}
          </When>
        </div>
      </div>
    </Fragment>
  )
}

LoadedFiles.defaultProps = {
  query: undefined,
}

LoadedFiles.propTypes = {
  query: PropTypes.string
}

export default LoadedFiles;