import { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import { Edit, Refresh, WithTooltip } from 'basic-components';
import { globalActions } from 'primary-components';

import { basicTimeseriesActions } from 'actions/timeseries';
import { ExcelUtils, TimeSeriesUtils } from 'utils';

import './LoadedSeriesRow.scss';

const LoadedSeriesRow = ({ address, groupName, refreshSerie, symbols, values, setTab, ...props }) => {
  const dispatch = useDispatch(),
        refreshSerieLocal = useCallback(() => 
          refreshSerie({ serie: { symbols, values, address, groupName, ...props } })
            .then(() => dispatch(globalActions.getMessageStore()).success("Time Series refreshed."))
        , [address, dispatch, groupName, props, refreshSerie, symbols, values]),
        goToAddress = useCallback(() => ExcelUtils.goToAddress(address), [address]),
        editSerie = useCallback(() => {
          TimeSeriesUtils.SUPPORTED_TS_PARAMS.filter(paramName => props[paramName]).forEach(paramName => dispatch(basicTimeseriesActions.onChange(props[paramName], paramName)))
          dispatch(basicTimeseriesActions.onChange(groupName, "groupName"))
          dispatch(basicTimeseriesActions.onChange(symbols, "symbol"))
          dispatch(basicTimeseriesActions.onChange(values, "columns"))
          dispatch(basicTimeseriesActions.onChange(address, "address"))
          setTab("ADD NEW")
        }, [address, dispatch, groupName, props, setTab, symbols, values]),
        localSymbols = useMemo(() => {
          const finalSymbols = {}
          Object.entries(symbols).forEach(([key, value]) => {
            if(!TimeSeriesUtils.SUPPORTED_TS_PARAMS.includes(key)) {
              finalSymbols[key] = value
            }
          })
          return finalSymbols
        }, [symbols])

  return (
    <div className="ng-office-app__authed__content__body__item__row ng-office-app__authed__content__body__item__row--loaded-serie">
      <div className="ng-office-app__authed__content__body__item__row__cell ng-office-app__authed__content__body__item__row__cell--group">
        <div className="ng-office-app__authed__content__body__item__row__cell__top">
          Group:
        </div>
        <div className="ng-office-app__authed__content__body__item__row__cell__bottom">
          {groupName}
        </div>
      </div>
      <div className="ng-office-app__authed__content__body__item__row__cell ng-office-app__authed__content__body__item__row__cell--address">
        <div className="ng-office-app__authed__content__body__item__row__cell__top">
          Cell Reference:
        </div>
        <div 
            className="ng-office-app__authed__content__body__item__row__cell__bottom"
            onClick={goToAddress}
        >        
          {address}
        </div>
      </div>
      {Object.entries(localSymbols).map(([key, value]) => (
        <div 
            className="ng-office-app__authed__content__body__item__row__cell ng-office-app__authed__content__body__item__row__cell--symbol"
            key={key}
        >
          <div className="ng-office-app__authed__content__body__item__row__cell__top">
            {key}:
          </div>
          <div className="ng-office-app__authed__content__body__item__row__cell__bottom">        
            {value}
          </div>
        </div>
      ))}
      <div className="ng-office-app__authed__content__body__item__row__cell ng-office-app__authed__content__body__item__row__cell--columns">
        <div className="ng-office-app__authed__content__body__item__row__cell__top">
          Columns:
        </div>
        <div className="ng-office-app__authed__content__body__item__row__cell__bottom">        
          {values.join(",")}
        </div>
      </div>
      <div className="ng-office-app__authed__content__body__item__row__cell ng-office-app__authed__content__body__item__row__cell--icons">
        <WithTooltip
            data-place="left"
            text="Download latest data."
        >
          <div 
              className="ng-office-app__authed__content__body__item__row__cell__icon"
              onClick={refreshSerieLocal}
              style={{ marginRight: "16px" }}
          >
            <Refresh
                color="blue-bright"
                height={16}
                width={16}
            />
          </div>
        </WithTooltip>
        <WithTooltip
            data-place="left"
            text="Edit formula."
        >
          <div 
              className="ng-office-app__authed__content__body__item__row__cell__icon"
              onClick={editSerie}
          >
            <Edit
                color="blue-bright"
                height={16}
                width={16}
            />
          </div>
        </WithTooltip>
      </div>
    </div>
  )
}
LoadedSeriesRow.defaultProps = {
  address: "",
  symbols: {},
  values: []
}

LoadedSeriesRow.propTypes = {
  address: PropTypes.string,
  groupName: PropTypes.string.isRequired,
  refreshSerie: PropTypes.func.isRequired,
  setTab: PropTypes.func.isRequired,
  symbols: PropTypes.object,
  values: PropTypes.array,
}

export default LoadedSeriesRow;