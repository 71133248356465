import { Fragment, memo, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import { Add, IconButton, WithTooltip } from 'basic-components';
import { globalActions } from 'primary-components';

import FORMULAS from 'resources/constants/Formulas.js';
import { ExcelUtils, GAUtils } from 'utils';

import './Actions.scss';

const Actions = ({ name, groupName }) => {
  const dispatch = useDispatch(),
        add = useCallback(() => {
          GAUtils.sendEvent({
            category: "action",
            action: "add_file",
            label: "add"
          })
          if(!name) {
            dispatch(globalActions.getMessageStore()).error("File does not exist")
            return
          }
          ExcelUtils
            .addFormulaToCell({ formula: `=${FORMULAS.LOAD_FILE}("${name}","${groupName}")` })
        }, [dispatch, groupName, name])
        
  return (
    <Fragment>
      <WithTooltip
          data-place="top"
          short
          text="Add"
      >
        <IconButton 
            className="ng-office-app__authed__content__body__item__body__scroll__row__action ng-office-app__authed__content__body__item__body__scroll__row__action--add"
            onClick={add}
        >
          <Add/>
        </IconButton>
      </WithTooltip>
    </Fragment>
  )
}

Actions.propTypes = {
  groupName: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired
}

export default memo(Actions);