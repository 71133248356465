import { forwardRef, Fragment, useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ArrowDown, ArrowUp, Calendar, DatePicker, Dropdown, Radio, TextField, TimeField } from 'basic-components';
import { dateBetweenTypes, TimeService, tsDataRanges, tsDataRangesObject, tsLastTypes } from 'dashboard-services';

import { basicTimeseriesActions } from 'actions/timeseries';

import { Else, If, Then, When } from 'react-if';

import classNames from 'classnames';
import './Options.scss';

const UTC = { label: TimeService.UTC, value: TimeService.UTC }
const javaTimeZonesWithDefault = [UTC].concat(TimeService.javaTimeZones.map(t => ({ label: t, value: t })))
const Options = forwardRef((_, ref) => {
  const dispatch = useDispatch(),
        { timezone, dateRange, lastValue, lastType, from, fromTime, to, toTime, fromSelected, toSelected } = useSelector(state => state.timeseriesState),
        [areOptionsExpanded, setAreOptionsExpanded] = useState(false),
        onChange = useCallback((val, { name }) => dispatch(basicTimeseriesActions.onChange(val, name)), [dispatch]),
        fromTimeoutRef = useRef(),
        toTimeoutRef = useRef(),
        onChangeRadio = useCallback((value, { name }) => {
          onChange(value, { name })
          if(value === "date") {
            if(!from || from?.length < 10) {
              onChange(to, { name: "from" })
            } else if(!to || to?.length < 10) {
              onChange(to, { name: "to" })
            }
          }
        }, [from, onChange, to]),
        onChangeFrom = useCallback(val => {
          onChange(val, { name: "from" })
          if(toSelected === "date" && (!to || to?.length < 10)) {
            onChange(to, { name: "to" })
          }
        }, [onChange, to, toSelected]),
        onChangeTo = useCallback(val => {
          onChange(val, { name: "to" })
          if(fromSelected === "date" && (!from || from?.length < 10)) {
            onChange(val, { name: "from" })
          }
        }, [from, fromSelected, onChange]),
        onChangeFromTime = useCallback((value, { name }) => {
          clearTimeout(fromTimeoutRef.current)
          fromTimeoutRef.current = setTimeout(() => onChange(TimeService.getTimeWithSeconds(value), name), 500)
        }, [onChange]),
        onChangeToTime = useCallback((value, { name }) => {
          clearTimeout(toTimeoutRef.current)
          toTimeoutRef.current = setTimeout(() => onChange(TimeService.getTimeWithSeconds(value), name), 500)
        }, [onChange]),
        swapExpand = useCallback(() => setAreOptionsExpanded(a => !a), []),
        defaultDateRange = useMemo(() => tsDataRanges.find(d => d.value === dateRange), [dateRange]),
        defaultLastType = useMemo(() => tsLastTypes.find(t => t.value === lastType), [lastType]),
        defaultTimeZone = useMemo(() => javaTimeZonesWithDefault.find(t => t.value === timezone), [timezone])

  useEffect(() => () => {
    clearTimeout(fromTimeoutRef.current)
    clearTimeout(toTimeoutRef.current)
  }, [])

  useImperativeHandle(ref, () => ({
    getParams: () => ({
      timezone,
      dateRange,
      from,
      to,
      fromSelected,
      toSelected,
      fromTime,
      toTime,
      lastType,
      lastValue
    })
  }), [dateRange, from, fromSelected, fromTime, lastType, lastValue, timezone, to, toSelected, toTime])

  return (
    <div className={classNames(
      "ng-office-app__authed__content__body__item__options",
      { "ng-office-app__authed__content__body__item__options--is-expanded": areOptionsExpanded },
    )}>
      <div 
          className="ng-office-app__authed__content__body__item__options__top"
          onClick={swapExpand}
      >
        <div className="ng-office-app__authed__content__body__item__options__top__icon">
          <Calendar
              color="blue-bright"
              height={16}
              width={16}
          />
        </div>
        <div className="ng-office-app__authed__content__body__item__options__top__label">
          Date range
        </div>
        <div className="ng-office-app__authed__content__body__item__options__top__arrow">
          <If condition={areOptionsExpanded}>
            <Then>
              <ArrowUp
                  color="blue-bright"
                  height={24}
                  width={24}
              />
            </Then>
            <Else>
              <ArrowDown
                  color="blue-bright"
                  height={24}
                  width={24}
              />
            </Else>
          </If>
        </div>
      </div>
      <div className="ng-office-app__authed__content__body__item__options__bottom">
        <div 
            className="ng-office-app__authed__content__body__item__options__bottom__label"
            style={{ marginTop: 4 }}
        >
          Timezone
        </div>
        <div className="ng-office-app__authed__content__body__item__options__bottom__value">
          <Dropdown
              defaultValue={defaultTimeZone}
              fixed
              menuPortalTarget={document.querySelector('.ng-office-app')}
              name="timezone"
              noError
              onChange={onChange}
              options={javaTimeZonesWithDefault}
              variant="border"
          />
        </div>
        <div className="ng-office-app__authed__content__body__item__options__bottom__label">
          Date range
        </div>
        <div className="ng-office-app__authed__content__body__item__options__bottom__value">
          <Dropdown
              defaultValue={defaultDateRange}
              fixed
              menuPortalTarget={document.querySelector('.ng-office-app')}
              name="dateRange"
              noError
              onChange={onChange}
              options={tsDataRanges}
              variant="border"
          />
        </div>
        <When condition={dateRange === tsDataRangesObject.last || dateRange === tsDataRangesObject.next}>
          {() => (
            <div className="ng-office-app__authed__content__body__item__options__bottom__row">
              <div className="ng-office-app__authed__content__body__item__options__bottom__row__item">
                <div className="ng-office-app__authed__content__body__item__options__bottom__label">
                  Type
                </div>
                <div className="ng-office-app__authed__content__body__item__options__bottom__value">
                  <Dropdown
                      defaultValue={defaultLastType}
                      fixed
                      menuPortalTarget={document.querySelector('.ng-office-app')}
                      name="lastType"
                      noError
                      onChange={onChange}
                      options={tsLastTypes}
                      variant="border"
                  />
                </div>
              </div>
              <div className="ng-office-app__authed__content__body__item__options__bottom__row__item">
                <div className="ng-office-app__authed__content__body__item__options__bottom__label">
                  Amount
                </div>
                <div className="ng-office-app__authed__content__body__item__options__bottom__value">
                  <TextField
                      defaultValue={lastValue}
                      min={1}
                      name="lastValue"
                      noError
                      onChange={onChange}
                      type="number"
                      variant="border"
                  />
                </div>
              </div>
            </div>
          )}
        </When>
        <When condition={dateRange === tsDataRangesObject.between}>
          {() => (
            <Fragment>
              <div className="ng-office-app__authed__content__body__item__options__bottom__row">
                <div className="ng-office-app__authed__content__body__item__options__bottom__label">From</div>
                <div className="ng-office-app__authed__content__body__item__options__bottom__pickers">
                  <Radio
                      checked={fromSelected === dateBetweenTypes.today}
                      groupName="fromSelected"
                      name={dateBetweenTypes.today}
                      onChange={onChangeRadio}
                  >
                    Today  
                  </Radio>
                  <div className="ng-office-app__authed__content__body__item__options__bottom__pickers__dates">
                    <Radio checked={fromSelected === dateBetweenTypes.date}
                        groupName="fromSelected"
                        name={dateBetweenTypes.date}
                        onChange={onChangeRadio}
                    >
                      {""}
                    </Radio>
                    <DatePicker
                        defaultValue={from}
                        disabled={fromSelected !== dateBetweenTypes.date}
                        name="from"
                        noError
                        onChange={onChangeFrom}
                    />
                    <TimeField
                        defaultValue={fromTime}
                        disabled={fromSelected !== dateBetweenTypes.date}
                        name="fromTime"
                        noError
                        onChange={onChangeFromTime}
                        shouldValidateWithSeconds={false}
                        withSeconds
                    />
                  </div>
                </div>
              </div>
              <div className="ng-office-app__authed__content__body__item__options__bottom__row">
                <div className="ng-office-app__authed__content__body__item__options__bottom__label">To</div>
                <div className="ng-office-app__authed__content__body__item__options__bottom__pickers">
                  <Radio 
                      checked={toSelected === dateBetweenTypes.today}
                      groupName="toSelected"
                      name={dateBetweenTypes.today}
                      onChange={onChangeRadio}
                  >
                    Today  
                  </Radio>
                  <div className="ng-office-app__authed__content__body__item__options__bottom__pickers__dates">
                    <Radio 
                        checked={toSelected === dateBetweenTypes.date}
                        groupName="toSelected"
                        name={dateBetweenTypes.date}
                        onChange={onChangeRadio}
                    >
                      {""}
                    </Radio>
                    <DatePicker
                        defaultValue={to}
                        disabled={toSelected !== dateBetweenTypes.date}
                        name="to"
                        noError
                        onChange={onChangeTo}
                    />
                    <TimeField
                        defaultValue={toTime}
                        disabled={toSelected !== dateBetweenTypes.date}
                        name="toTime"
                        noError
                        onChange={onChangeToTime}
                        shouldValidateWithSeconds={false}
                        withSeconds
                    />
                  </div>
                </div>
              </div>
            </Fragment>
          )}
        </When>
      </div>
    </div>
  )
})

Options.displayName = "Options"

export default Options;