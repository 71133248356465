import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { Button, Copy as CopyIcon, Dropdown, FormRow, Label, TextField } from 'basic-components';
import { globalActions } from 'primary-components';

import { basicUploadActions } from 'actions/upload';

const DefineFile = ({ onChangeRaw }) => {
  const dispatch = useDispatch(),
        {
          sheetName,
          firstCell,
          lastCell,
        } = useSelector(state => state.uploadState),
        [localSheets, setLocalSheets] = useState([]),
        defaultSheetObject = useMemo(() => localSheets.find(ls => ls.value === sheetName), [localSheets, sheetName]),
        onChangeCellsRange = useCallback(() => dispatch(basicUploadActions.defineFileRange()), [dispatch]),   
        refreshSheetList = useCallback(async context => {
          const workbook = context.workbook
          workbook.worksheets.load("items")
          
          return context.sync().then(() => {
            setLocalSheets(workbook.worksheets.items.map(sheet => ({ label: sheet.name, value: sheet.name })))
          })
        }, []),
        eventResults = useMemo(() => [], [])

  useEffect(() => {
    Excel.run(async context => {
      const workbook = context.workbook,
            onAddHandler =workbook.worksheets.onAdded.add(() => refreshSheetList(context)),
            onDeleteHandler = workbook.worksheets.onDeleted.add(() => refreshSheetList(context)),
            onNameChangedHandler = workbook.worksheets.onNameChanged.add(() => refreshSheetList(context))

      await context.sync()

      eventResults.push(onAddHandler)
      eventResults.push(onDeleteHandler)
      eventResults.push(onNameChangedHandler)
      await refreshSheetList(context)

    }).catch(err => {
      console.error(err)
      dispatch(globalActions.getMessageStore()).error(String(err))
    })

    return () => {
      eventResults.forEach(async eventResult => {
        await Excel.run(eventResult.context, async context => {
          eventResult.remove();
          await context.sync();
        });
      })
    }
  }, [dispatch, eventResults, refreshSheetList])
  
  return (
    <div className="ng-office-app__authed__content__body__section">
      <div className="ng-office-app__authed__content__body__section__title">
        DEFINE FILE
      </div>
      <div 
          className="ng-office-app__authed__content__body__section__top-button"
          onClick={onChangeCellsRange}
      >
        <CopyIcon
            color="blue-bright"
            height={16}
            width={16}
        />
        <Button
            size="small"
            variant="text"
        >
          Use cell range selected on the sheet
        </Button>
      </div>
      <FormRow 
          className="ng-office-app__authed__content__body__section__body"
          isVertical
      >
        <Label>Sheet source</Label>
        <Dropdown
            defaultValue={defaultSheetObject}
            name="sheetName"
            onChange={onChangeRaw}
            options={localSheets}
            placeholder="Choose sheet to upload data from"
            required
            variant="border"
        />
        <Label>Choose cells range</Label>
        <div className="ng-office-app__authed__content__body__section__body__cell__adress">
          <TextField
              defaultValue={firstCell}
              name="firstCell"
              onChange={onChangeRaw}
              placeholder="A0"
              required
              variant="border"
          />
          <div className="ng-office-app__authed__content__body__section__body__cell__adress__colon">:</div>
          <TextField
              defaultValue={lastCell}
              name="lastCell"
              onChange={onChangeRaw}
              placeholder="E0"
              required
              variant="border"
          />
        </div>
      </FormRow>
    </div>
  )
}

DefineFile.propTypes = {
  onChangeRaw: PropTypes.func.isRequired
}

export default DefineFile