import PropTypes from 'prop-types';

import './HeaderNoSearch.scss';

const HeaderNoSearch = ({ icon, title }) => (
  <div className="ng-office-header-no-search">
    <div className="ng-office-header-no-search__icon">
      {icon}
    </div>
    <div className="ng-office-header-no-search__title">
      {title}
    </div>
  </div>
)

HeaderNoSearch.propTypes = {
  icon: PropTypes.element.isRequired,
  title: PropTypes.string.isRequired,
}

export default HeaderNoSearch;