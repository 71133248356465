import { createRoot } from "react-dom/client"

import { createStore, applyMiddleware } from 'redux'
import thunkMiddleware from 'redux-thunk'
import { Provider } from 'react-redux'

import properties from 'resources/constants/properties.json'

import App from "./App"

import { officeApp, initialState } from 'reducers'

const title = "NorthGravity Add-in";
const FUNCTIONS_NAMESPACE = properties.functionsNamespace

const rootElement = document.getElementById("container");
const root = createRoot(rootElement);

const renderApp = () =>
  root.render(
    <Provider store={window["ngStore" + FUNCTIONS_NAMESPACE]}>
      <App title={title}/>
    </Provider>
  )

Office.onReady(() => {
  if(!window["ngStore" + FUNCTIONS_NAMESPACE]) {
    window["ngStore" + FUNCTIONS_NAMESPACE] = createStore((state, action) => officeApp(state, action), initialState, applyMiddleware(thunkMiddleware))
  }
  renderApp();
});