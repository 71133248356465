import { Fragment, useCallback, useEffect, useLayoutEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Container, Store } from 'basic-components';
import { HooksService } from 'dashboard-services';
import { apiConfigActions, Fetching, globalActions, loginActions } from 'primary-components';

import { basicSettingsActions } from 'actions/settings';
import getApiConfig from 'api/ApiConfig';
import { routing } from 'routing';
import { AuthSingleton, AuthUtils, GAUtils } from 'utils';

import Authed from './Authed';
import DataLake from './Authed/DataLake';
import TimeSeries from './Authed/TimeSeries';
import Upload from './Authed/Upload';
import LoginPageWrapper from './LoginPageWrapper';
import Settings from './Settings';

import { Case, Else, If, Switch, Then, When } from 'react-if';

import './App.scss';

const DEFAULT_COMPONENT = routing.LAKE.name
const App = () => {
  const dispatch = useDispatch(),
        [isDone, setIsDone] = useState(false),
        [activeComponent, setActiveComponent] = useState(DEFAULT_COMPONENT),
        [isTaskPaneVisible, setIsTaskPaneVisible] = useState(false),
        { company, userName } = useSelector(state => state.settingsState),
        prevUserName = HooksService.usePrevious(userName),
        token = useSelector(state => state.primaryComponentsState.loginState.token),
        messageStore = useSelector(state => state.globalState.MessageStore),
        toastsStore = useSelector(state => state.globalState.ToastsStore),
        isFetching = useSelector(state => state.globalState.fetchingRequests.filter(f => !f.noFetching).length > 0),
        tokenExist = token != null && typeof token != "undefined" && token !== "",
        width = HooksService.useWindowSize()?.width,
        onWindowResize = useCallback(() => {
          if(width < 612){
            dispatch(globalActions.onChange(true, "isMobile"))
          } else {
            dispatch(globalActions.onChange(false, "isMobile"))
          }
        }, [dispatch, width]),
        handleIdChange = useCallback(() => {
          const id = document.body.id;
          setActiveComponent(id || DEFAULT_COMPONENT);
        }, []),
        loadSettings = useCallback(() => {
          dispatch(basicSettingsActions.getSettings(token))
            .catch(() => {
              dispatch(loginActions.logOut())
            })
        }, [dispatch, token])

  useEffect(() => {
    handleIdChange()
    const observer = new MutationObserver(mutations => {
      mutations.forEach(mutation => {
        if (mutation.type === 'attributes' && mutation.attributeName === 'id') {
          handleIdChange();
        }
      })
    });
    observer.observe(document.body, { attributes: true })

    return () => {
      observer.disconnect()
    };
  }, [handleIdChange])

  useLayoutEffect(() => {
    dispatch(loginActions.logoutAllTabs())
    dispatch(apiConfigActions.changeApiConfig(getApiConfig))
    AuthSingleton.getInstance().refreshNGToken.finally(() => {
      setIsDone(true)
    })
  }, [dispatch])

  useEffect(() => {
    if(!messageStore) {
      dispatch(globalActions.onChange(new Store(), "MessageStore"))
    }
    if(!toastsStore) {
      dispatch(globalActions.onChange(new Store(), "ToastsStore"))
    }
  }, [dispatch, messageStore, toastsStore])

  useEffect(() => {
    onWindowResize()
  }, [width, onWindowResize])

  useEffect(() => {
    Office?.addin?.onVisibilityModeChanged(function(args) {
      if (args.visibilityMode === "Taskpane") {
        setIsTaskPaneVisible(true)
      } else {
        setIsTaskPaneVisible(false)
      }
    })
  }, [])

  useEffect(() => {
    token && !userName && loadSettings()
  }, [loadSettings, token, userName])

  useEffect(() => {
    if(userName) {
      GAUtils.init({ userName, company })
    }
  }, [company, dispatch, userName])

  useEffect(() => {
    if(userName && prevUserName !== userName) {
      setTimeout(() => AuthUtils.disableLogInButton(), 10)
    } else if(!userName && prevUserName !== userName) {
      AuthUtils.enableLogInButton()
    }
  }, [prevUserName, userName])
  
  if(width === 0 || !isTaskPaneVisible) {
    return null;
  }

  return (
    <div className="ng-office-app">
      {toastsStore && (
        <Container
            globalTimeout={30000}
            position="top-right"
            store={toastsStore}
            variant="toasts"
        />
      )}
      {messageStore && (
        <Container
            globalTimeout={5000}
            position="top-center"
            store={messageStore}
            variant="message"
        />
      )}
      <If condition={!isDone}>
        <Then>
          {() => <Fetching isFetching/>}  
        </Then>
        <Else>
          {() => (
            <Fragment>
              <Fetching
                  isFetching={isFetching}
              />
              <If condition={activeComponent === routing.SETTINGS.name}>
                <Then>
                  {() => (
                    <Settings isLoggedIn={tokenExist}/>
                  )}
                </Then>
                <Else>
                  {() => (
                    <Fragment>
                      <When condition={tokenExist}>
                        {() => (
                          <Authed>
                            <Switch>
                              <Case condition={activeComponent === routing.LAKE.name}>
                                <DataLake/>
                              </Case>
                              <Case condition={activeComponent === routing.TS.name}>
                                <TimeSeries/>
                              </Case>
                              <Case condition={activeComponent === routing.UPLOAD.name}>
                                <Upload/>
                              </Case>
                            </Switch>
                          </Authed>
                        )}
                      </When>
                      <When condition={!tokenExist || activeComponent === routing.LOGIN.name}>
                        {() => (
                          <LoginPageWrapper
                              activeComponent={activeComponent}
                          />
                        )}
                      </When>
                    </Fragment>
                  )}
                </Else>
              </If>
            </Fragment>
          )}
        </Else>
      </If>
    </div>
  )
}

export default App;