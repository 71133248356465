import { useCallback, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { dateBetweenTypes, TimeService, tsDataRangesObject } from 'dashboard-services';

import { basicTimeseriesActions } from 'actions/timeseries';
import FORMULAS from 'resources/constants/Formulas.js';
import { ExcelUtils, GAUtils } from 'utils';

import Creator from './Creator';
import Options from './Options';
import Preview from './Preview';

import dayjs from 'dayjs';

import './AddNewTimeSeries.scss';

const AddNewTimeSeries = () => {
  const { groupName, symbol, columns, address } = useSelector(state => state.timeseriesState),
        dispatch = useDispatch(),

        addToSheet = useCallback(() => {
          GAUtils.sendEvent({
            category: "action",
            action: "add_ts",
            label: "add"
          })
          let formula = `=${FORMULAS.LOAD_TS}("${groupName}","${columns.join(",")}",${Object.entries(symbol).map(([key, value]) => `"${key}=${value}"`).join(",")}`
          const { timezone, dateRange, from, to, fromSelected, toSelected, fromTime, toTime, lastType, lastValue } = optionsRef.current.getParams()
          if(timezone) {
            formula += `, "timeZone=${timezone}"`;
          }
          if(dateRange) {
            formula += `, "range=${dateRange}"`;
          }
          if(dateRange === tsDataRangesObject.between) {
            formula += `, "startDate=${fromSelected === dateBetweenTypes.date ? dayjs(from + (fromTime ? "T" + fromTime : "T00:00:00.000")).format(TimeService.ISO_DATE_TIME_FORMAT_BACKEND_WITH_SECONDS_STYLE) : fromSelected}"`;
            formula += `, "endDate=${toSelected === dateBetweenTypes.date ? dayjs(to  + (toTime ? "T" + toTime : "T23:59:59.999")).format(TimeService.ISO_DATE_TIME_FORMAT_BACKEND_WITH_SECONDS_STYLE) : toSelected}"`;
          }
          if(dateRange === tsDataRangesObject.last || dateRange === tsDataRangesObject.next) {
            formula += `, "lastType=${lastType}"`;
            formula += `, "lastTypeAmount=${lastValue}"`;
          }
          formula += ")"
          ExcelUtils.addFormulaToCell({ formula, address })
        }, [address, columns, groupName, symbol]),
        optionsRef = useRef()

        useEffect(() => () => {
          dispatch(basicTimeseriesActions.clear())
        }, [dispatch])

  return (
    <div className="ng-office-app__authed__content__body__item ng-office-app__authed__content__body__item--add-serie">
      <Options
          ref={optionsRef}
      />
      <Creator/>
      <Preview addToSheet={addToSheet}/>
    </div>
  )
}

export default AddNewTimeSeries;