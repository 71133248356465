import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import { LoadMoreDropdown, Tag } from 'basic-components';
import { DataStorageApi, DropdownService, HooksService, ReduxService } from 'dashboard-services';

import { basicTimeseriesActions } from 'actions/timeseries';
import getApiConfig from 'api/ApiConfig';

import './Creator.scss';

const ALL = { label: "All", value: "*" }
const Creator = () => {
  const dispatch = useDispatch(),
        { groupName, key, value } = useSelector(state => state.timeseriesState),
        symbol = useSelector(state => state.timeseriesState.symbol, shallowEqual),
        columnsRaw = useSelector(state => state.timeseriesState.columns, shallowEqual),
        columns = useMemo(() => columnsRaw?.map(c => c === ALL.value ? ALL : { label: c, value: c }), [columnsRaw]),
        isMounted = HooksService.useIsMounted(),
        [hasMoreKeys, setHasMoreKeys] = useState(true),
        [hasMoreValues, setHasMoreValues] = useState(true),
        [hasMoreColumns, setHasMoreColumns] = useState(true),
        onChange = useCallback((val, { name }) => dispatch(basicTimeseriesActions.onChange(val, name)), [dispatch]),

        { searchGroups, hasMoreGroups } = DropdownService.useSearchGroups(dispatch(getApiConfig()), isMounted, { symbolsOnly: true }),
        searchKeysRef = useRef(),
        searchKeys = useCallback((input, size) => {
          if(!groupName) {
            return Promise.resolve()
          }
          searchKeysRef.current?.cancel?.()
          const filterSymbolObj = {}
          Object.entries(symbol).forEach(([key, value]) => {
            filterSymbolObj[key] = value === "*" ? [] : [value];
          })
          searchKeysRef.current = 
            new DataStorageApi(dispatch(getApiConfig()))
              .getSymbolKeys(groupName, input ? `*${input}*` :  "*", { symbols: filterSymbolObj, from: 0, size: size === 0 ? 20 : size, useEs: true })
              .noFetching(true)
              .cancelable(true)
              .build()
              .call()
          return searchKeysRef.current.promise
            .then((response = {}) => {
              setHasMoreKeys(response?.totalSize > size)
              return (response?.items || []).map(item => ({ value: item.name, label: item.name }))
            })
        }, [symbol, dispatch, groupName]),
        searchValuesRef = useRef(),
        searchValues = useCallback((input, size) => {
          if(!groupName || !key) {
            return Promise.resolve()
          }
          searchValuesRef.current?.cancel?.()
          const filterSymbolObj = {}
          Object.entries(symbol).forEach(([key, value]) => {
            filterSymbolObj[key] = value === "*" ? [] : [value];
          })
          searchValuesRef.current = 
            new DataStorageApi(dispatch(getApiConfig()))
              .getSymbolValues(key, groupName, input ? `*${input}*` :  "*", { symbols: filterSymbolObj, from: 0, size: size === 0 ? 20 : size, useEs: true })
              .noFetching(true)
              .cancelable(true)
              .build()
              .call()
          return searchValuesRef.current.promise
            .then((response = {}) => {
              setHasMoreValues(response?.totalSize > size)
              return [ALL].concat((response?.items || []).map(item => ({ value: item.name, label: item.name })))
            })
        }, [groupName, key, symbol, dispatch]),
        searchColumnsRef = useRef(),
        searchColumns = useCallback((input, size) => {
          if(!groupName) {
            return Promise.resolve()
          }
          searchColumnsRef.current?.cancel?.()
          searchColumnsRef.current =
            new DataStorageApi(dispatch(getApiConfig()))
              .getColumns(groupName, input ? `*${input}*` :  "*", { symbols: symbol, from: 0, size: size === 0 ? 20 : size, useEs: true })
              .noFetching(true)
              .cancelable(true)
              .build()
              .call()
          return searchColumnsRef.current.promise
            .then((response = {}) => {
              isMounted.current && setHasMoreColumns(response.totalSize > size)
              return [ALL].concat((response?.items || []).map(item => ({ value: item?.name, label: item?.name })))
            })
        }, [dispatch, groupName, symbol, isMounted]),
        removeSymbolKey = useCallback(key => {
          dispatch(basicTimeseriesActions.updateSymbol(s => {
            const newS = ReduxService.updateObject(s)
            delete newS[key]
            return newS;
          }))
        }, [dispatch]),
        keyRef = useRef(),
        valueRef = useRef(),
        columnRef = useRef()

  useEffect(() => {
    if(key && value) {
      dispatch(basicTimeseriesActions.updateSymbol(s => ReduxService.updateObject(s, { [key]: value })))
      onChange(undefined, { name: "key" })
      onChange(undefined, { name: "value" })
      keyRef.current?.dropdown?.current?.clearValue?.()
      valueRef.current?.dropdown?.current?.clearValue?.()
      columnRef.current?.dropdown?.current?.clearValue?.()
    }
  }, [value, key, onChange, dispatch])

  useEffect(() => {
    keyRef.current?.refreshOptions?.()
    valueRef.current?.refreshOptions?.()
    columnRef.current?.refreshOptions?.()
  }, [symbol])

  useEffect(() => {
    valueRef.current?.refreshOptions?.()
  }, [key])

  useEffect(() => () => {
    searchValuesRef.current?.cancel?.()
    searchKeysRef.current?.cancel?.()
    searchColumnsRef.current?.cancel?.()
  }, [])

  useEffect(() => {
    columnRef.current?.dropdown?.current?.setValue(columns)
  }, [columns])

  return (
    <div className="ng-office-app__authed__content__body__item__creator">
      <div className="ng-office-app__authed__content__body__item__creator__title">
        CREATE SYMBOL FILTER
      </div>
      <div className="ng-office-app__authed__content__body__item__creator__row">
        <LoadMoreDropdown
            autoFocus
            defaultValue={groupName}
            hasMore={hasMoreGroups}
            loadOptions={searchGroups}
            menuPlacement="bottom"
            name="groupName"
            noError
            onChange={onChange}
            placeholder="Choose group"
            variant="border"
        />
      </div>
      <div className="ng-office-app__authed__content__body__item__creator__row">
        <LoadMoreDropdown
            disabled={!groupName}
            hasMore={hasMoreKeys}
            key={'key'+groupName}
            loadOptions={searchKeys}
            menuPlacement="bottom"
            name="key"
            noError
            onChange={onChange}
            placeholder="Choose key"
            ref={keyRef}
            variant="border"
        />
        <LoadMoreDropdown
            disabled={!key || !groupName}
            hasMore={hasMoreValues}
            key={'value'+groupName}
            loadOptions={searchValues}
            menuPlacement="bottom"
            name="value"
            noError
            onChange={onChange}
            placeholder="Choose value"
            ref={valueRef}
            variant="border"
        />
      </div>
      <div 
          className="ng-office-app__authed__content__body__item__creator__row"
          style={{ marginBottom: 16, display: "block" }}
      >
        {Object.entries(symbol || {}).map(([key, value]) => (
          <Tag
              key={key}
              onClear={() => removeSymbolKey(key)}
          >
            <div className="ng-office-app__authed__content__body__item__creator__row__tag__key">
              {key}:
            </div>
            <div className="ng-office-app__authed__content__body__item__creator__row__tag__value">
              {value}
            </div>
          </Tag>
        ))}
      </div>
      <div className="ng-office-app__authed__content__body__item__creator__title">
        SELECT COLUMNS
      </div>
      <div className="ng-office-app__authed__content__body__item__creator__row">
        <LoadMoreDropdown
            defaultValue={columns}
            disabled={Object.keys(symbol).length === 0 || !groupName}
            hasMore={hasMoreColumns}
            loadOptions={searchColumns}
            multi
            name="columns"
            noError
            onChange={onChange}
            placeholder="Columns"
            ref={columnRef}
            variant="border"
        />
      </div>
    </div>
  )
}

export default Creator;