import { createRef, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { Add as AddIcon, Button, Dropdown, FormRow, Label, LoadMoreDropdown, TextField } from 'basic-components';
import { DropdownService, HooksService, newDlFileTypes, newDlFileTypesObj } from 'dashboard-services';
import { globalActions } from 'primary-components';

import { basicUploadActions, fileUploadActions } from 'actions/upload';
import getApiConfig from 'api/ApiConfig';

import MetadataRow from './MetadataRow';

const EXTENSION = ".csv"
const FileConfig = ({ onChangeRaw, fileNameRef, groupNameRef, fileTypeRef }) => {
  const isMounted = HooksService.useIsMounted(),
        dispatch = useDispatch(),
        {
          errors,
          groupName,
          fileName,
          fileType,
          fields,
        } = useSelector(state => state.uploadState),
        [refs, setRefs] = useState([]),
        { searchGroups, hasMoreGroups } = DropdownService.useSearchGroups(dispatch(getApiConfig()), isMounted, { writeOnly: true }),
        localFileTypes = useMemo(() => {
          const defaultTypes = [
            newDlFileTypes.find(n => n.value === newDlFileTypesObj.SOURCE),
            newDlFileTypes.find(n => n.value === newDlFileTypesObj.NCSV),
            newDlFileTypes.find(n => n.value === newDlFileTypesObj.D_NCSV),
          ]
          return defaultTypes;
        }, []),
        defaultFileTypeObj = useMemo(() => localFileTypes.find(l => l.value === fileType), [fileType, localFileTypes]),
        addField = useCallback(() => dispatch(fileUploadActions.addField()), [dispatch]),
        getGroupNameValidator = useMemo(() => [{
          message: errors?.find(e => e.field === `groupName`)?.message || "",
          validator: () => errors?.findIndex(e => e.field === `groupName`) === -1
        }], [errors]),
        onGroupCreate = useCallback(val => 
          dispatch(basicUploadActions.onGroupCreate(val))
            .then(() => onChangeRaw(val, { name: 'groupName' }))
        , [dispatch, onChangeRaw]),
        groupRef = useRef(),
        onChangeGroupName = useCallback((value, { data }) => {
          if(data?.isPublic) {
            dispatch(globalActions.getToastsStore()).warning("The selected group is public. Do you want to proceed?", "Selected group is public", {
              withCancel: false,
              confirmText: "Confirm",
              confirmAction: () => {
                onChangeRaw(value, { name: 'groupName' })
              },
              cancelAction: () => {
                groupRef.current.dropdown.current.setValue(groupName)
              }
            })
          } else {
            onChangeRaw(value, { name: 'groupName' })
          }
        }, [dispatch, groupName, onChangeRaw]),
        getNameOnFocus = useCallback(() => {
          const newValue = fileName || ""
          if(newValue?.endsWith(EXTENSION)) {
            return newValue.replace(/\.[^/.]+$/, "")
          } else {
            return newValue
          }
        }, [fileName]),
        onFocus = useCallback(() => onChangeRaw(getNameOnFocus(), { name: "fileName" }), [getNameOnFocus, onChangeRaw]),
        getNameOnBlur = useCallback(() => {
          const newValue = fileName || ""
          if(newValue?.endsWith(EXTENSION) || newValue === "") {
            return newValue.trim()
          } else {
            return newValue.trim() + EXTENSION
          }
        }, [fileName]),
        onBlur = useCallback(() => onChangeRaw(getNameOnBlur(), { name: "fileName" }), [getNameOnBlur, onChangeRaw])

  useEffect(() => {
    setRefs(refs => (
      Array(fields.length).fill().map((_, i) => refs[i] || createRef())
    ));
  }, [fields?.length])

  useEffect(() => {
    const lastRef = refs[fields.length - 1]
    lastRef?.current && lastRef.current.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
  }, [refs, fields?.length])

  return (
    <div className="ng-office-app__authed__content__body__section">
      <div className="ng-office-app__authed__content__body__section__title">
        FILE CONFIGURATION
      </div>
      <FormRow 
          className="ng-office-app__authed__content__body__section__body"
          isVertical
      >
        <Label>File name</Label>
        <TextField
            defaultValue={fileName}
            name="fileName"
            onBlur={onBlur}
            onChange={onChangeRaw}
            onFocus={onFocus}
            placeholder="Create file name"
            ref={fileNameRef}
            required
            variant="border"
        />
        <Label>Upload group</Label>
        <LoadMoreDropdown
            allowCreateWhileLoading={false}
            creatable
            defaultValue={groupName}
            fixed
            hasMore={hasMoreGroups}
            loadOptions={searchGroups}
            menuPortalTarget={document.querySelector('.ng-office-app')}
            name="groupName"
            onChange={onChangeGroupName}
            onCreateOption={onGroupCreate}
            placeholder="Choose upload group or type to create new"
            ref={groupNameRef}
            required
            size="large"
            validators={getGroupNameValidator}
            variant="boarder"
        />
        <Label>File type</Label>
        <Dropdown
            defaultValue={defaultFileTypeObj}
            menuPortalTarget={document.querySelector('.ng-office-app')}
            name="fileType"
            onChange={onChangeRaw}
            options={localFileTypes}
            ref={fileTypeRef}
            required
            variant="border"
        />
        <Label>Metadata</Label>
        <div 
            className="ng-office-app__authed__content__body__section__body__icon-button"
            onClick={addField}
        >
          <AddIcon
              color="blue-bright"
              height={20}
              width={20}
          />
          <Button
              size="small"
              variant="text"
          >
            Add metadata set
          </Button>
        </div>
        {fields.map((field, index) => (
          <MetadataRow
              index={index}
              key={"Metadata" + index}
              ref={refs[index]}
          />
        ))}
      </FormRow>
    </div>
  )
}

FileConfig.propTypes = {
  fileNameRef: PropTypes.any.isRequired,
  fileTypeRef: PropTypes.any.isRequired,
  groupNameRef: PropTypes.any.isRequired,
  onChangeRaw: PropTypes.func.isRequired
}

export default FileConfig