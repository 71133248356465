import PropTypes from 'prop-types';

import './Authed.scss';

const Authed = ({ children }) => (
  <div className="ng-office-app__authed">
    {children}
  </div>
)

Authed.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.element]).isRequired
}

export default Authed;