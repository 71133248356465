import { forwardRef, memo, useCallback } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { Bin, FormRow, IconButton, TextField } from 'basic-components';

import { fileUploadActions } from 'actions/upload';

import { When } from 'react-if';

import './MetadataRow.scss';

const MetadataRow = forwardRef(({ index }, ref) => {
  const { 
          name, 
          value, 
          canBeRemoved 
        } = useSelector(state => state.uploadState.fields[index] || {}, shallowEqual),
        dispatch = useDispatch(),
        onChange = useCallback((value, { name }) => dispatch(fileUploadActions.changeField(value, name, index)), [index, dispatch]),
        removeField = useCallback(() => canBeRemoved && dispatch(fileUploadActions.removeField(index)), [index, canBeRemoved, dispatch])

  return (
    <FormRow 
        className="ng-office-app__authed__content__body__section__body__metadata-row"
        ref={ref}
    >
      <TextField
          defaultValue={name}
          disabled={!canBeRemoved}
          name="name"
          onChange={onChange}
          placeholder="Name"
          required
          variant="border"
      />
      <TextField
          defaultValue={value}
          name="value"
          onChange={onChange}
          placeholder="Value"
          required
          variant="border"
      />
      <When condition={canBeRemoved}>
        {() => (
          <IconButton 
              className="ng-office-app__authed__content__body__section__body__metadata-row__remove-field"
              onClick={removeField}
          >
            <Bin
                color="blue-bright"
                height={20}
                width={20}
            />
          </IconButton>
        )}
      </When>
    </FormRow>
  )
})

MetadataRow.displayName="MetadataRow"

MetadataRow.propTypes = {
  index: PropTypes.number.isRequired
}

export default memo(MetadataRow);