import { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { NoResults } from 'basic-components';
import { CommonService, DataLakeApi, HooksService } from 'dashboard-services';
import { GroupFilter } from 'primary-components';

import getApiConfig from 'api/ApiConfig';

import AddFileRow from './AddFileRow';

import { When } from 'react-if';
import InfiniteScroll from 'react-infinite-scroller';

import './AddNewFile.scss';

const SEARCH_PREFIX = "SEARCH_FILES", REFRESH_PREFIX = "REFRESH_FILES", SCROLL_PREFIX = "SCROLL_FILES"
const AddNewFile = () => {
  const dispatch = useDispatch(),
        [groupName, setGroupName] = useState(),
        [files, setFiles] = useState(),
        [hasNextPage, setHasNextPage] = useState(true),
        query = useSelector(state => state.datalakeState.query),
        searchRef = useRef(),
        search = useCallback(({ from = 0, size = CommonService.getRequestSize(), noFetching = false, requestIdPrefix = SEARCH_PREFIX } = {}) => {
          let finalQuery = `*${query || ""}*.csv&latest=true`
          if(groupName) {
            finalQuery += `&groupName.keyword=${groupName}`
          }
          searchRef.current?.cancel?.()
          searchRef.current = new DataLakeApi(dispatch(getApiConfig()))
            .searchFiles({ query: finalQuery, from, size })
            .noFetching(noFetching)
            .noErrorMessage(noFetching)
            .withRequestIdPrefix(requestIdPrefix)
            .cancelable(true)
            .build()
            .call()
            
          return searchRef.current.promise.then(({ items, totalSize, from }) => {
              const concat = from !== 0;
              setFiles(oldDps => {
                if(concat) {
                  return oldDps.concat(items)
                }
                return items;
              })
              setHasNextPage(totalSize > from + items.length)
            })
        }, [dispatch, groupName, query]),
        refresh = useCallback(({ noFetching = false } = {}) => search({ 
          from: 0, 
          size: files?.length < CommonService.MIN_REQUEST_SIZE ? CommonService.MIN_REQUEST_SIZE : files?.length, 
          noFetching, 
          requestIdPrefix: REFRESH_PREFIX
        }), [files?.length, search]),
        scroll = useCallback(() => search({ from: files?.length, requestIdPrefix: SCROLL_PREFIX }), [files?.length, search]),
        scrollRef = useRef()
  
  HooksService.useInterval(() => {
    refresh({ noFetching: true })
  }, 30000)

  useEffect(() => {
    search()
  }, [search])

  useEffect(() => () => {
    searchRef.current?.cancel?.()
  }, [])

  return (
    <div className="ng-office-app__authed__content__body__item ng-office-app__authed__content__body__item--add-file">
      <div className="ng-office-app__authed__content__body__item__headers">
        <div className="ng-office-app__authed__content__body__item__headers__header">
          Name
        </div>
        <div className="ng-office-app__authed__content__body__item__headers__header ng-office-app__authed__content__body__item__headers__header--group">
          <GroupFilter
              className="ng-office-app__authed__content__body__item__headers__header__group-filter"
              groupName={groupName}
              onChange={setGroupName}
              size="normal"
          />
        </div>
      </div>
      <div 
          className="ng-office-app__authed__content__body__item__body"
          ref={scrollRef}
      >
        <When condition={files?.length === 0}>
          {() => (
            <div className="no-results-wrapper">
              <NoResults
                  optionalText={query ? "Try to search a diffrent keyword" : "Navigate to Add New tab to add data."}
                  title="No data has been found."
              />
            </div>
          )}
        </When>
        <When condition={files?.length > 0}>
          {() => (
            <InfiniteScroll
                className="ng-office-app__authed__content__body__item__body__scroll"
                hasMore={hasNextPage}
                initialLoad={false}
                loadMore={scroll}
                threshold={800}
                useWindow={false}
            >
            {files.map(({ groupName, fid, fileName, ...rest }, index) => (
              <AddFileRow
                  {...rest}
                  groupName={groupName}
                  id={fid}
                  key={fid + index}
                  name={fileName}
                  scrollRef={scrollRef}
              />
            ))}
            </InfiniteScroll>
          )}
        </When>
      </div>
    </div>
  )
}

AddNewFile.defaultProps = {
  query: undefined,
}

AddNewFile.propTypes = {
  query: PropTypes.string,
}

export default AddNewFile;